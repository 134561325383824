import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                <Link className="logo-footer" to="#">Norwich Beer & Gin Festival</Link>
                                <p className="mt-4">Follow us on our social media for updates.</p>
                                <ul className="list-unstyled social-icon social mb-0 mt-4">
                                    {/* <li className="list-inline-item">
                                        <Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link>
                                    </li> */}
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/RoundTableNo1" rel='noreferrer noopener' target="_blank" className="rounded"><i className="mdi mdi-twitter" title="Twitter"></i></a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 className="text-light footer-head">Festival</h4>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link smooth to="/#about" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> About us</Link></li>
                                    <li><Link smooth to="/#sponsors" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Sponsors</Link></li>
                                    <li><Link smooth to="/#schedule" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Schedule</Link></li>
                                    <li><Link smooth to="/#table" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Table</Link></li>
                                    {/* <li><Link smooth to="/#price" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Pricing</Link></li> */}
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 className="text-light footer-head">Useful Links</h4>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="/page-privacy" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Privacy Policy</Link></li>
                                    <li><Link to="/terms-conditions" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Terms of Services</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                {/* <h4 className="text-light footer-head">Newsletter</h4>
                                <p className="mt-4">Sign up and receive the latest tips via email.</p>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="foot-subscribe form-group position-relative">
                                                <label>Write your email <span className="text-danger">*</span></label>
                                                <i className="mdi mdi-email ml-3 icons"></i>
                                                <input type="email" name="email" id="emailsubscribe" className="form-control pl-5 rounded" placeholder="Your email : " required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <input type="submit" id="submitsubscribe" name="send" className="btn btn-primary w-100" value="Subscribe" />
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                    </div>
                </footer>
                <hr />
                <footer className="footer footer-bar">
                    <div className="container text-center">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <div className="text-sm-left">
                                    <p className="mb-0">© Round Table {new Date().getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </React.Fragment>
        );
    }
}

export default Footer;
