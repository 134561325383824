import React from 'react';

// Import all components
const Event = React.lazy(() => import('./pages/Event'));
const PagePrivacy = React.lazy(() => import('./pages/PagePrivacy'));
const PageTermsAndConditions = React.lazy(() => import('./pages/PageTermsAndConditions'));
const Components = React.lazy(() => import('./pages/Components'));

const routes = [

    // public Routes
    { path: '/components', component: Components },
    { path: '/page-privacy', component: PagePrivacy },
    { path: '/terms-conditions', component: PageTermsAndConditions },
    { path: '/index', component: Event }

];

export default routes;