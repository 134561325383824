import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { withRouter } from "react-router";

import logoImage from '../../images/NRT-Beer-Festival-Logo-Final.png';

class Topbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.toggleLine = this.toggleLine.bind(this);
    }

    toggleLine() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    componentDidMount() {
        this.findActiveMenuItem(this.props.location.hash);
    }

    findActiveMenuItem(hash) {
        var i = 0;
        var matchingMenuItem = null;
        var ul = document.getElementById("top-menu");

        var itemParents = ul.getElementsByTagName("li");
        for (i = 0; i < itemParents.length; ++i) {
            var li = itemParents[i];
            li.classList.remove('active');
        }

        var items = ul.getElementsByTagName("a");
        for (i = 0; i < items.length; ++i) {
            if (hash === items[i].hash) {
                matchingMenuItem = items[i];
                break;
            }
        }

        if (matchingMenuItem) {
            const parent = matchingMenuItem;
            if (parent) {
                parent.classList.add('active'); // li
                const parent1 = parent.parentElement;
                parent1.classList.add('active'); // li
                if (parent1) {
                    const parent2 = parent1.parentElement;
                    parent2.classList.add('active'); // li
                    if (parent2) {
                        const parent3 = parent2.parentElement;
                        parent3.classList.add('active'); // li
                        if (parent3) {
                            const parent4 = parent3.parentElement;
                            parent4.classList.add('active'); // li
                        }
                    }
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <header id="topnav" className="defaultscroll sticky">
                    <div className="container">
                        <div>
                            <img className="logo-img" src={logoImage} alt="" />
                            <Link className="logo" to="/index">
                                Norwich Beer & Gin Festival
                            </Link>
                        </div>

                        <div className="buy-button">
                            {/* <Link to="#" className="btn btn-primary">Buy Now</Link> */}
                        </div>

                        <div className="menu-extras">
                            <div className="menu-item">
                                <Link to="#" onClick={this.toggleLine} className={this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"} >
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div id="navigation" style={{ display: this.state.isOpen ? "block" : "none" }}>
                            <ul className="navigation-menu" id="top-menu">
                                <li>
                                    <Link smooth to="/#about" onClick={(event) => { this.findActiveMenuItem('#about') }}>About</Link>
                                </li>
                                <li>
                                    <Link smooth to="/#sponsors" onClick={(event) => { this.findActiveMenuItem('#sponsors') }}>Sponsors</Link>
                                </li>
                                <li>
                                    <Link smooth to="/#schedule" onClick={(event) => { this.findActiveMenuItem('#schedule') }}>Schedule</Link>
                                </li>
                                <li>
                                    <Link smooth to="/#table" onClick={(event) => { this.findActiveMenuItem('#table') }}>Round Table</Link>
                                </li>
                                <li>
                                    {/* <Link smooth to="/#price" onClick={(event) => { this.findActiveMenuItem('#price') }}>Pricing</Link> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            </React.Fragment >
        );
    }
}

export default withRouter(Topbar);